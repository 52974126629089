<template>
  <div class="manager-member-page">
    <div class="search-wrapper">
      <el-form
        :inline="true"
        :model="searchForm"
        class="search-form"
        size="small"
        ref="searchForm"
      >
        <el-form-item label="">
          <el-input v-model="searchForm.search" placeholder="昵称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
            size="mini"
            class="green-btn"
            >查询</el-button
          >
          <el-button @click="handleClear" size="mini" class="green-plain-btn"
            >刷新</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table-wrapper">
      <div class="box-table">
        <el-table
          v-loading="pageLoading"
          :data="pageData"
          style="width: 100%"
          height="100%"
          @selection-change="selectionChange"
          @sort-change="handleSortChange"
        >
          <el-table-column type="selection" width="55" align="center">
          </el-table-column>
          <el-table-column type="index" width="55" label="编号" align="center">
          </el-table-column>
          <el-table-column prop="nickname" label="昵称" align="center">
          </el-table-column>
          <el-table-column label="性别" align="center">
            <template slot-scope="scope">
              {{
                scope.row.gender === 0
                  ? "女"
                  : scope.row.gender == 1
                  ? "男"
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column prop="birth" label="出生年月" align="center">
          </el-table-column>
          <el-table-column
            prop="resourceCount"
            label="作品数量"
            align="center"
            sortable="custom"
          >
          </el-table-column>
          <el-table-column
            prop="score"
            label="积分"
            align="center"
            sortable="custom"
          >
          </el-table-column>
          <el-table-column
            prop="followCount"
            label="关注量"
            align="center"
            sortable="custom"
          ></el-table-column>
          <el-table-column label="注册时间" align="center">
            <template slot-scope="scope">
              {{ $dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
            </template>
          </el-table-column>
          <el-table-column label="最近登录时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.loginDate ? $dayjs(scope.row.loginDate).format('YYYY-MM-DD HH:mm') : '-' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <div class="opt-wrapper">
                <div
                  class="opt-icon"
                  title="加入黑名单"
                  @click="handleToBlack(scope.row)"
                >
                  <i class="iconfont icon-lahei"></i>
                </div>
                <div
                  v-if="isSuperAdmin && scope.row.type == 1"
                  class="opt-icon"
                  title="设为管理员"
                  @click="handleToAdmin(scope.row)"
                >
                  <i class="iconfont icon-sheweiguanliyuan1"></i>
                </div>
                <div
                  v-if="isSuperAdmin && scope.row.type == 2"
                  class="opt-icon"
                  title="取消管理员"
                  @click="handleCacncelAdmin(scope.row)"
                >
                  <i class="iconfont icon-quxiaoguanliyuan1"></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pageParams.total"
          :current-page="pageParams.currentPage"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogFormShow"
      :close-on-click-modal="false"
      custom-class="form-dialog"
      :before-close="handleFormClose"
      title="拉黑原因"
      width="30%"
    >
      <el-form :model="form" :rules="rules" ref="xForm" class="x-form">
        <el-form-item prop="comment">
          <el-input
            type="textarea"
            v-model="form.comment"
            placeholder="请填写详细原因"
          ></el-input>
        </el-form-item>
        <el-form-item class="btn-group">
          <el-button plain class="s-btn" @click="onSubmit">提交</el-button>
          <el-button class="c-btn" @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      SUCCESS_CODE: 0,
      pageParams: {
        total: 0,
        currentPage: 1,
        pageNum: 1,
        pageSize: 10,
      },
      searchForm: {
        search: "",
      },
      pageData: [],
      pageLoading: false,
      idList: [],
      dialogFormShow: false,
      form: {
        comment: "",
      },
      rules: {
        comment: [
          { required: true, message: "请填写详细原因", trigger: "blur" },
        ],
      },
      isSuperAdmin: false,
      sortParams: {
        sort: "",
        type: "",
      },
    };
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // 判断是否是超级管理员
    this.isSuperAdmin = userInfo.su;
    this.getPageData(true);
  },
  methods: {
    handleSearch() {
      this.getPageData(true);
    },
    // 清空搜索
    handleClear() {
      this.searchForm.search = "";
      this.$refs.searchForm.resetFields();
      this.getPageData(true);
    },
    selectionChange(data) {
      let idList = [];
      if (data.length) {
        data.forEach((item) => {
          idList.push(item.id);
        });
        this.idList = idList;
      }
    },
    // 获取设计师列表
    getPageData(flag) {
      if (flag) {
        this.pageData = [];
        this.pageParams = {
          pageNum: 1,
          pageSize: 10,
          total: 0,
          currentPage: 0,
        };
      }
      let params = Object.assign({}, this.pageParams);
      params.search = this.searchForm.search;
      params.sort = this.sortParams.sort;
      params.type = this.sortParams.type;
      this.pageLoading = true;
      this.$http
        .get("/sys-user/listPage", { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            if (res.data && res.data.records) {
              this.pageData = res.data.records;
              this.pageParams.total = res.data.total;
              this.pageParams.currentPage = res.data.current;
            }
          } else {
            this.$message.error("获取数据失败" + res.msg);
          }
          this.pageLoading = false;
        })
        .catch((err) => {
          this.pageLoading = false;
          this.$message.error("获取数据失败," + err);
        });
    },
    // 分页
    handlePageChange(current) {
      this.pageParams.currentPage = current;
      this.pageParams.pageNum = current;
      this.getPageData();
    },
    handleFormClose(done) {
      this.$refs.xForm.resetFields();
      done();
    },
    // 加入黑名单
    handleToBlack(item) {
      this.dialogFormShow = true;
      this.idList = [item.id];
    },
    handleBatchToBlack() {
      if (this.idList.length) {
        this.dialogFormShow = true;
      } else {
        this.$message.warning("请选择需要加入黑名单的设计师！");
      }
    },
    toBlack() {
      let userIds = this.idList.join();
      let comment = this.form.comment;
      let params = {
        userIds,
        comment,
      };
      this.$http.post("/sys-user/blacklisted", null, { params }).then((res) => {
        if (res.code == this.SUCCESS_CODE) {
          this.getPageData(true);
          this.idList = [];
          this.$refs.xForm.resetFields();
          this.dialogFormShow = false;
        }
      });
    },
    onSubmit() {
      this.$refs.xForm.validate((valid) => {
        if (valid) {
          this.$confirm("此操作将拉黑设计师, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
          })
            .then(() => {
              this.toBlack();
            })
            .catch(() => {});
        } else {
          return;
        }
      });
    },
    onCancel() {
      this.$refs.xForm.resetFields();
      this.dialogFormShow = false;
    },
    // 设为管理员
    handleToAdmin(data) {
      this.$confirm("此操作将设置此设计师为管理员, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        this.setAdmin(data, 1);
      });
    },
    // 取消管理员
    handleCacncelAdmin(data) {
      this.$confirm("此操作将取消此设计师管理员权限, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        this.setAdmin(data, 2);
      });
    },
    // 管理员设置
    setAdmin(data, act) {
      let params = {
        uids: data.id,
        act,
      };
      this.$http
        .post("/sys-user/setAdmin", null, { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success(res.msg);
            this.getPageData(true);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 排序
    handleSortChange(data) {
      // console.log(data);
      let order = data.order;
      switch (order) {
        case "descending":
          this.sortParams.type = "desc";
          this.sortParams.sort = data.prop;
          break;
        case "ascending":
          this.sortParams.type = "asc";
          this.sortParams.sort = data.prop;
          break;
        default:
          this.sortParams.sort = "";
          this.sortParams.type = "";
          break;
      }
      this.getPageData(true);
    },
  },
};
</script>